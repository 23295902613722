import { gql, useQuery } from '@apollo/client';
import { isAfter } from 'date-fns';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';

import { CustomImage, DynamicImage, Slider } from '@components/common';
import SkeletonComponent from '@components/common/Skeleton';
import { GetPromotionDocument } from '@generated/graphql';
import { isMobile } from 'react-device-detect';
import { getHomeBanner, IHomeBanner } from 'src/utils/getHomeBanner';
import FlashSaleBanner from '../FlashSaleBanner';

export const GET_CMS_PAGE = gql`
  query GetCMSPage($id: Int!) {
    cmsPage(id: $id) {
      url_key
      content
      content_heading
      title
      page_layout
      meta_title
      meta_keywords
      meta_description
    }
    storeConfig {
      id
      root_category_id
    }
  }
`;

const MainBanner = () => {
  const [autoplayTime, setAutoplayTime] = useState(20000);
  const [promotionalDateIsExpired, setPromotionalDateIsExpired] =
    useState(true);

  const { data, loading } = useQuery(GET_CMS_PAGE, {
    fetchPolicy: 'cache-and-network',
    variables: { id: 2 },
    onCompleted: () => {
      // This is a workaround to only set the autoplay time after there is data for the carousel
      // It's necessary because once the data is fetched the carousel could be already mounted and the autoplay skip the first slide
      // This solves https://developer.chrome.com/docs/lighthouse/performance/offscreen-images
      setTimeout(() => {
        setAutoplayTime(9000);
      }, 5000);
    }
  });

  const homeBanner = useMemo(() => {
    if (!isEmpty(data?.cmsPage)) {
      return getHomeBanner(data?.cmsPage);
    }

    return {} as IHomeBanner;
  }, [data]);

  const { data: dataPromotion } = useQuery(GetPromotionDocument, {
    variables: { sku: '' },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    const promotionalProduct = dataPromotion?.promotionBf;

    if (!isEmpty(promotionalProduct)) {
      const productsFiltered = promotionalProduct.filter(
        product => !isAfter(new Date(), new Date(product.date_end))
      );

      setPromotionalDateIsExpired(productsFiltered.length === 0);
    }
  }, [dataPromotion?.promotionBf]);

  return (
    <div
      style={{ transformOrigin: 'center top' }}
      className="w-full h-[fit-content] border-box flex justify-center lg:pt-2 lg:mb-2 sm:px-4"
    >
      <div className="w-full aspect-[13/14] lg:aspect-[41/14] max-w-[987px] bn_rotativo">
        {loading && !homeBanner.data && (
          <SkeletonComponent
            width="100%"
            className="min-h-[474px] lg:min-h-[340px] max-w-[987px]"
          />
        )}
        {homeBanner?.data && (
          <Slider
            showDots
            autoPlay
            autoPlaySpeed={autoplayTime}
            numberOfSlides={1}
            renderDotsOutside={isMobile}
            arrowClassName="block absolute"
            customArrow="bg-[#FBFBFE] hover:bg-white-dark"
            customArrowColor="text-primary-medium hover:text-white-dark"
            containerClass="lg:max-w-[987px] max-w-[100%] lg:w-[100dvw]"
          >
            {homeBanner?.data?.map((bannerData, index) => (
              <a
                key={bannerData?.link}
                href={bannerData?.link.replace(/\.html/, '')}
                className="w-full"
              >
                <DynamicImage
                  mobileSrc={bannerData?.mobile_image}
                  desktopSrc={bannerData?.desktop_image}
                  alt={bannerData?.description}
                  mobileProps={{
                    width: 412,
                    height: 444,
                    quality: 60
                  }}
                  desktopProps={{
                    width: 987,
                    height: 336
                  }}
                  priority={index === 0}
                  className="cursor-pointer lg:shadow-md lg:rounded-md aspect-[13/14] lg:aspect-[41/14] w-full bg-contain"
                />
              </a>
            ))}
          </Slider>
        )}
      </div>

      {loading && !homeBanner.aside ? (
        <SkeletonComponent
          width="20rem"
          height={isMobile ? '474px' : '340px'}
          className="ml-6 h-full"
        />
      ) : (
        <div className="hidden lg:block border-box ml-6 w-[312px] border-box relative add-commit">
          {!isEmpty(homeBanner?.aside) && (
            <>
              {!promotionalDateIsExpired ? (
                <FlashSaleBanner
                  setPromotionalDateIsExpired={setPromotionalDateIsExpired}
                />
              ) : (
                <div className="h-[336px] w-[312px]">
                  <Link
                    href={homeBanner?.aside[0]?.link}
                    className="h-full w-full block border-box px-2 cursor-pointer relative"
                  >
                    <CustomImage
                      src={homeBanner.aside[0]?.image}
                      alt={homeBanner.aside[0]?.description}
                      width={312}
                      height={336}
                      quality={60}
                      className="h-full rounded-md object-cover"
                      priority={true}
                    />
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default MainBanner;
